.analytics-card {
  text-align: center;
  border-radius: 20px;
  border: solid 1px black;
  width: 170px;
  height: 120px;
  .value {
    margin-top: 20px;
    font-size: 30px;
    margin-bottom: 10px;
  }
  .description {
    font-size: 20px;
  }

  &.smaller {
    width: 120px;
    height: 50px;
    margin: 3px;

    .value {
      margin: 5px 0 -5px;
      font-size: 15px;
    }

    .description {
      font-size: 12px;
    }
    &.error {
      background: #ffbfbf;
    }
  }
}
