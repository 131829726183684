@import 'variables';

.ant-btn {
  &.ant-btn-link {
    color: $primary-color;
  }
}

.sidebar-content {
  .ant-menu {
    background-color: transparent;
    border-right: none;
    .ant-menu-item {
      color: white;
      a {
        color: white;
      }
    }
    .ant-menu-item-selected {
      background-color: $navigation-button;
      color: white;
      a {
        color: white;
      }
    }
    .ant-menu-item-active {
      background-color: $navigation-button;
      color: white;
      a {
        color: white;
      }
    }
    ::after {
      border-right: 4px solid $dark-background;
    }
  }
}

.ant-avatar {
  border: 1px solid #546e7a;
  background-color: #f5f5f6;
  img {
    object-fit: contain;
    -webkit-filter: drop-shadow(5px 5px 5px rgba(0, 8, 58, 0.6));
    filter: drop-shadow(5px 5px 5px rgba(0, 8, 58, 0.6));
  }
}

.ant-row.ant-form-item {
  margin-bottom: 10px;
}