@import 'variables';

.page-layout-comp {
  display: flex;
  flex-direction: column;
  .header {
    .title {
      h1 {
        margin: 0;
      }
    }
  }
  .content {
    flex: 1;
    overflow: auto;
    max-height: calc(100vh - 65px);
  }
}

.form-label {
  line-height: 32px;
}

.login-container {
  background: $light-background;
}

.sidebar-content {
  background: $navigation-background;
}
.app-content {
  background: $light-background;

  .header {
    background: $dark-background;
  }
}

.contact-support {
  .ant-descriptions-view > table > tbody > tr > th {
    width: 200px;
  }
}
