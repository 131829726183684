@import 'variables';

// padding
@for $i from 0 to 5 {
  .pd-#{$i * 5} {
    padding: $i * 5px;
  }
  .pd-t-#{$i * 5} {
    padding-top: $i * 5px;
  }
  .pd-l-#{$i * 5} {
    padding-left: $i * 5px;
  }
  .pd-b-#{$i * 5} {
    padding-bottom: $i * 5px;
  }
  .pd-r-#{$i * 5} {
    padding-right: $i * 5px;
  }
  .pd-h-#{$i * 5} {
    padding-left: $i * 5px;
    padding-right: $i * 5px;
  }
  .pd-v-#{$i * 5} {
    padding-top: $i * 5px;
    padding-bottom: $i * 5px;
  }
}

// margin
@for $i from 0 to 5 {
  .mg-#{$i * 5} {
    margin: $i * 5px;
  }
  .mg-t-#{$i * 5} {
    margin-top: $i * 5px;
  }
  .mg-l-#{$i * 5} {
    margin-left: $i * 5px;
  }
  .mg-b-#{$i * 5} {
    margin-bottom: $i * 5px;
  }
  .mg-r-#{$i * 5} {
    margin-right: $i * 5px;
  }
  .mg-h-#{$i * 5} {
    margin-left: $i * 5px;
    margin-right: $i * 5px;
  }
  .mg-v-#{$i * 5} {
    margin-top: $i * 5px;
    margin-bottom: $i * 5px;
  }
}

.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}

// flex
.d-flex {
  display: flex;
}
.flex {
  flex: 1;
}
.flex-no-shrink {
  flex-shrink: 0;
}
.f-wrap {
  flex-wrap: wrap;
}

// flex directions
$flex-directions: 'row' row, 'col' column, 'row-r' row-reverse, 'col-r' column-reverse;
@each $name, $value in $flex-directions {
  .f-#{$name} {
    flex-direction: $value;
  }
}

// flex align items
$align-items: 'start' flex-start, 'end' flex-end, 'center' center, 'baseline' baseline, 'stretch' stretch;
@each $name, $value in $align-items {
  .ai-#{$name} {
    align-items: $value;
  }
}
// flex justify contents
$justify-contents: 'start' flex-start, 'end' flex-end, 'center' center, 'space-between' space-between,
  'space-around' space-around;
@each $name, $value in $justify-contents {
  .jc-#{$name} {
    justify-content: $value;
  }
}

// font-weights
@for $i from 1 to 8 {
  .fw-#{$i * 100} {
    font-weight: $i * 100;
  }
}

.ta-center {
  text-align: center;
}

.align-self-flex-end {
  align-self: flex-end;
}

.justify-start {
  justify-content: start;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: end;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.white-text {
  color: white;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #7a7a7a;
}

#rounded-corner {
  position: relative;
  background-color: $dark-background;
  width: 24px;
  height: 24px;
  div {
    background-color: $light-background;
    border-top-left-radius: 100%;
    width: 100%;
    height: 100%;
  }
}

#sidemenu-profile {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 128px;
  padding: 24px 8px;
  margin-bottom: 16px;
  border-bottom-right-radius: 24px;
  background-color: $dark-background;
}

#sidemenu-collapse-button {
  position: absolute;
  top: 16px;
  right: 16px;
}

#profile-picture-input {
  cursor: pointer;
}

#powered-logo {
  display: flex;
  flex-direction: column;
  background-color: $dark-background;
  border-radius: 24px 24px 0 0;
  padding: 24px;
  gap: 16px;
  img {
    align-self: center;
    width: 100%;
  }
}

#powered-logo-collapsed {
  display: flex;
  flex-direction: column;
  background-color: $dark-background;
  border-radius: 24px 24px 0 0;
  padding: 16px 4px;
  gap: 16px;
  div {
    display: none;
  }
  img {
    align-self: center;
    width: 100%;
  }
}

.editing-row {
  .ant-table-cell {
    padding-left: 2px;
    padding-right: 2px;
  }
  .location-btn, .editor-btn {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.error-row {
  background-color: #ffe8e8;
  &:hover {
    td {
      background-color: #ffcbcb !important;
    }
  }
}

.grouped-table {
  .vehicle {
    background-color: #1990ff26;
  }
  .editing-row {
    td:nth-child(0) {
      display: flex;
      color: blue;
    }
  }

  .highlight-error {
    background-color: #ffbfbf;
  }
}